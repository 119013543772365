import React from "react"
import { Link as GLink, graphql, StaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Chip } from "@material-ui/core"

const pageQuery = graphql`
  query {
    allBlogPost {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`

const CategoriesIndex = () => {
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        return (
          <React.Fragment>
            <Typography>Categories</Typography>
            <Grid container spacing={1}>
              {data.allBlogPost.group.map(category => {
                return (
                  <Grid item key={category.fieldValue}>
                    <Chip
                      component={GLink}
                      to={`/blog/categories/${category.fieldValue}`}
                      label={`${category.fieldValue} (${category.totalCount})`}
                      clickable
                    />
                  </Grid>
                )
              })}
            </Grid>
          </React.Fragment>
        )
      }}
    />
  )
}

export default CategoriesIndex
