import React from "react"
import { Link as GLink } from "gatsby"
import Link from "@material-ui/core/Link"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"

import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Socials from "./socials"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}))

const Nav = styled.nav`
  display: flex;
`


class TabLinkSub extends React.Component {
  render() {
    return <Link component={GLink} {...this.props} />
  }
}

const TabLink = props => {
  return <Tab component={TabLinkSub} {...props} />
}


const Header = ({ location }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(location.pathname)

  const rootPage = "/" + value.split("/")[1]

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <header>
            <Nav id="site-nav" className={classes.root}>
              <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    noWrap
                  >
                    Psykar
                  </Typography>
                  <Socials/>
                </Toolbar>
                <Paper square={true}>
                  <Tabs
                    value={rootPage}
                    onChange={handleChange}
                    centered
                    variant="fullWidth"
                  >
                    {data.site.siteMetadata.menuLinks.map(({ name, link }) => (
                      <TabLink label={name} to={link} key={name} value={link} />
                    ))}
                  </Tabs>
                </Paper>
              </AppBar>
            </Nav>
          </header>
        )
      }}
    />
  )
}

export default Header
