import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Avatar from "@material-ui/core/Avatar"
import Email from "@material-ui/icons/Email"
import Link from "@material-ui/core/Link"
import FaMapMarkerAlt from "@material-ui/icons/LocationOn"
import Chip from "@material-ui/core/Chip"
import BlogDates from "./BlogDates"
import BlogCategories from "./BlogCategories"

const width = 40
const mobileWidth = 40
const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1, 1),
  },
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  footer: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
  avatar: {
    width: width,
    height: width,
  },
  avatarMobile: {
    width: mobileWidth,
    height: mobileWidth,
  },
  sidebarItem: {
    padding: theme.spacing(0, 2),
  },
  sublinks: {
    padding: theme.spacing(2, 2),
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
}))

const Sidebar = ({ isBlog }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query BioQuery {
      avatarMobile: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const sources = [
    data.avatarMobile.childImageSharp.fixed,
    // {
    // ...data.avatar.childImageSharp.fixed,
    // media: `(min-width: 1024px)`,
    // }
  ]
  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.sticky}>
        <Paper elevation={5} className={classes.sidebarAboutBox}>
          <Typography variant="h6" gutterBottom>
            <Grid container>
              <Grid item className={classes.sidebarItem}>
                <Avatar alt="Remy Sharp" className={classes.avatar}>
                  <Image fixed={sources} alt="Louis des Landes" />
                </Avatar>
              </Grid>
              <Grid item className={classes.sidebarItem}>
                Louis des Landes
              </Grid>
            </Grid>
          </Typography>
          <Typography>
            Mumblings of a Space focused Software Engineer
          </Typography>
          {/* TODO - archive months here? */}
          {/* <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
              Archives
            </Typography>
            {archives.map(archive => (
              <Link display="block" variant="body1" href="#" key={archive}>
                {archive}
              </Link>
            ))} */}
          <Chip
            avatar={
              <Avatar alt="Location">
                <FaMapMarkerAlt />
              </Avatar>
            }
            className={classes.chip}
            label="Adelaide, Australia"
          />
          <Chip
            avatar={
              <Avatar alt="Email">
                <Email />
              </Avatar>
            }
            className={classes.chip}
            label="admin@psykar.com"
            clickable
            component={Link}
            href="mailto://admin@psykar.com"
          />
        </Paper>
        {isBlog && (
          <div className={classes.sublinks}>
            <BlogDates />
            <BlogCategories />
          </div>
        )}
      </Paper>
      {/* Empty div so sticky works */}
      <div />
    </Grid>
  )
}

export default Sidebar
